import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from './registerServiceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/analytics';

require('bootstrap/dist/css/bootstrap.min.css')


// let config = {
//   apiKey: "AIzaSyDujZjKkXGShD3OSQaU0gmwjhfN61NhcPo",
//   authDomain: "community-htech-b827e.firebaseapp.com",
//   databaseURL: "https://community-htech-b827e.firebaseio.com",
//   projectId: "community-htech-b827e",
//   storageBucket: "community-htech-b827e.appspot.com",
//   messagingSenderId: "1035655095170",
//   appId: "1:1035655095170:web:db8e5f86deda37f294659b",
//   measurementId: "G-MHBEBPLEDG"
// };

//NEW CONFIG FOR NEW DB Firebase
let config = {
  apiKey: "AIzaSyC7ilyTL8cYl9j2qFlNHJM1Q8iOgNOqFyM",
  authDomain: "huskytechweb.firebaseapp.com",
  databaseURL: "https://huskytechweb-default-rtdb.firebaseio.com",
  projectId: "huskytechweb",
  storageBucket: "huskytechweb.appspot.com",
  messagingSenderId: "543967547760",
  appId: "1:543967547760:web:2f697d1a27d6e0558f3fb5",
  measurementId: "G-ERGDH0MQRY"
};
firebase.initializeApp(config);


ReactDOM.render((
  <Router>
    <App />
  </Router>
), document.getElementById('root'));
registerServiceWorker();
