import React, { useEffect, useState } from 'react';
import {Button, Row, Col, Container} from 'reactstrap';
import '../App.css';
import './SponsorshipPage.css';
import { logEvent }  from '../helpers';
import firebase from 'firebase/app';

const SponsorLogo = (props) => {
  return (
      <Col xs="6" sm="4" lg="3" className="logos-flex">
          <img src={props.src} className="s-img" alt={props.name}/>
      </Col>
    );
}

const SponsorshipPage = () => {
  let [sponsors, setSponsors] = useState([]);

  // connect to firebase and get the logos!
  useEffect(() => {
    firebase.database().ref("sponsorLogos").once("value", (snapshot) => {
      let sponsorsList = [];
      let sponsorsObj = snapshot.val();

      // List of standby companies for now, not to include on site
      const standbyCompanies = ["redfin", "nordstrom", "stripe", "porch", "palantir", "smartsheet", "godaddy"];
      
      Object.keys(sponsorsObj).forEach((sponsor) => {
        if (!standbyCompanies.includes(sponsor)) {
          sponsorsList.push(sponsorsObj[sponsor]);
        }
      });
      setSponsors(sponsorsList);
    });
  }, []);

  return (
      <div>
        <h1 className="htHeading htHeaderFont" id="sponsorHeader">Sponsors</h1>
        <div>
            <h3 className="htSubHeading htBodyFont" id="sponsorSub">We are proudly sponsored by...</h3>
            <Container id="marginOverride">
              <Row className="sponsor-row">
                {
                  sponsors.map((sponsor, index) => {
                      return <SponsorLogo key={'item-' + index} src={sponsor.src} name={sponsor.name}/>;
                  })
                }
              </Row>
            </Container>

            <Container id="sponsorInfoText">
              <Row>
                <Col sm="7" id="sponsorInfo">
                  <div className="whySponsor">
                      <h3 className="htSubHeading htBodyFont">Why Sponsor?</h3>
                      {/* 1. Potential - Fastest Growing RSO. Students Love us.
                      2. Bold Ideas - Connect Industry to Students. Big plans for the future.
                      3. Benefits - Recruiting + Marketing  */}
                        <p className="sponsorshipText">Potential</p>
                        <p id="whyDescription">
                          HuskyTech is the fastest growing Tech Organization at the University of Washington.<br />
                        </p>

                        <p className="sponsorshipText">Bold Ideas </p>
                        <p id="whyDescription">
                          We take calculated risks to challenge the status quo of student led organizations. <br />
                          Our 2020-2021 Goal: Rethink the way students get hired
                        </p>
                        <p className="sponsorshipText">Benefits </p>
                        <p id="whyDescription">
                        We offer various resources to assist with recruiting top tier talent and marketing sponsored events. <br />
                        </p>
                        <div id="joinContainer">
                          <Button href="mailto:htech@uw.edu?subject=Sponsorship Packet Request&body=
                          Hello HuskyTech Team, I'm interested in learning about your sponsorship package!" id="join-button"
                          target="_blank" onClick={() => logEvent("clicked_contact_us")}>Contact us to receive our Sponsorship Packet</Button>{' '}
                        </div>
                  </div>
                </Col>
                <Col sm="2"></Col>
                <Col sm="3">
                      <div id="quoteOne" className="quoteBox">
                        <p className="testimonial">Our sponsors get dedicated, one-on-one time with high-performing  UW students who are passionate about a future in tech.</p>
                      </div>
                      <div id="quoteTwo" className="quoteBox">
                        <p className="testimonial">HuskyTech offers the opportunity for sponsors to host unique events that will attract future interns and full-time employees.</p>
                      </div>
                      <div id="quoteThree" className="quoteBox">
                        <p className="testimonial">HuskyTech is proud to be connecting students and industry leaders for the past 4 years. </p>
                      </div>
                </Col>
              </Row>
            </Container>
        </div>
      </div>
  );
}

export default SponsorshipPage;