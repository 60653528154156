import React, { useState } from 'react';
import {Form, FormGroup, Modal, ModalHeader, ModalBody, Input, Col, Button} from 'reactstrap';
import validator from 'validator';
import linkedin from '../images/icons/icons8-linkedin-128.png';
import facebook from '../images/icons/icons8-facebook-64.png';
import emailIcon from '../images/icons/icons8-secured-letter-64.png';
import twitter from '../images/icons/icons8-twitter-64.png';
import instagram from '../images/icons/icons8-instagram-128.png';
import './ContactPage.css';

// SocialOutlet component designed to display a part of HuskyTech social media.
const SocialOutlet = (props) => {
  return (
    <div className="followUsRow">
      <a href={props.link} target='_blank' rel="noopener noreferrer" >
          <img className="followUsIcons"  src={props.image} alt={props.alt_text} />
          <p>{props.text}</p>
      </a>
    </div>
  );
}

const ContactPage = () => {
  const [name, changename] = useState('');
  const [email, changeemail] = useState('');
  const [message, changemessage] = useState('');
  const [modalMessage, changemodalMessage] = useState('Please fill out all of the fields');
  const [title, changetitle] = useState('Error');
  const [formValid, changeFormValid] = useState(false);
  const [showModal, changeShowModal] = useState(false);

  const social_media = [
    {link: "mailto:htech@uw.edu", image: emailIcon, alt_text: "click to email us", text: "HTECH@UW.EDU"},
    // {link: "https://www.facebook.com/HuskyTech/", image: facebook, alt_text: "click to see our facebook page",
    //  text: "FACEBOOK.COM/HUSKYTECH"},
    // {link: "https://twitter.com/uwhuskytech", image: twitter, alt_text: "click to see our twitter page", text: "@UWHUSKYTECH"},
    {link: "https://www.linkedin.com/company/18117451/", image: linkedin, alt_text: "click to see our linkedin page",
     text: "LINKEDIN.COM/COMPANY/HUSKY-TECH"},
     {link: "https://www.instagram.com/uwhuskytech/", image: instagram, alt_text: "click to see our instagram page",
     text: "uwhuskytech"}
  ];

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      changeemail(value);
    } else if (name === "name") {
      changename(value);
    } else if (name === "message") {
      changemessage(value);
    } 
  }

  // Validates if all fields have been filled
  const validateForm = () => {
    if (!validator.isEmail(email.toString())) {
      changemodalMessage("Please enter a valid email");
      changetitle("Error");
      changeFormValid(false);
    } else if (name === "" || message === "") {
      changemodalMessage("Please fill out all the fields");
      changetitle("Error");
      changeFormValid(false);
    } else {
      changetitle("Success!");
      changemodalMessage("Message sent");
      changeFormValid(true);
    }
  }

  const openModal = () => {
    validateForm();
    changeShowModal(true);
  }

  // dismiss after modal in 1.2 seconds
  const dismissModal = () => {
    setTimeout(() => {
      changeShowModal(false);
    }, 1500);
  }

  const renderForm = () => {
    // This form uses http://www.enformed.io/ Read more about enformed before modifying code
    return (
        <Form action="https://www.enformed.io/c6vha8oe" method="POST">
            <FormGroup row>
              <Col sm={10}>
                <Input type="name" name="name" placeholder="Name" value={name} onChange={(event) => handleUserInput(event)}/>
              </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={10}>
                  <Input type="email" name="email" placeholder="Email Address" value={email} onChange={(event) => handleUserInput(event)}/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={10}>
                  <Input type="textarea" name="message" placeholder="Message here" value={message} onChange={(event) => handleUserInput(event)}/>
                  {/* Prevents Bots from sending spamming email */}
                  <input type="hidden" name="*honeypot" />
                </Col>
            </FormGroup>
            {/* Submit only if form is Valid */}
            {formValid &&
              <Button id="submit-button" type="submit">Submit</Button>
            }
            {!formValid &&
              <Button id="submit-button" onClick={() => openModal()}>Submit</Button>
            }
            <FormGroup>
              {/* Reply to emails directly */}
              <input type="hidden" name="*reply" value="email" />
              {/* Add subject Line */}
              <input type="hidden" name="*subject" value="Thank you for contacting HuskyTech!" />
            </FormGroup>
            <Modal isOpen={showModal} onOpened={() => dismissModal()}>
              <ModalHeader>{title}</ModalHeader>
              <ModalBody>{modalMessage}</ModalBody>
            </Modal>
        </Form>
    );
  }

  return (
    <div className="container contactBox">
        <div className="row">
            <div className="col-12 col-md-6" id="desktopView">
                <h2>Connect with us.</h2>
                <p className="contactUs-text">
                  Follow us on social media to stay updated on all the amazing things we do.
                </p>
                {
                  social_media.map(media => {
                    return <SocialOutlet link={media.link} image={media.image}
                            text={media.text} alt_text={media.alt_text}/>
                  })
                }
            </div>
            <div className="col-12 col-md-6 contactForm">
                <h2>Send us a message</h2>
                <p className="contactUs-text">We love to help. Fill in the fields and we'll get back to you!</p>
                {renderForm()}
            </div>
        </div>
    </div>
  )
}

export default ContactPage;
