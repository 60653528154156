import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Spinner } from 'reactstrap';
import * as Showdown from "showdown";
import '../App.css';
import '../index.css';
import './Membership.css';
import imageOne from '../images/membership/memOne.png';
import imageTwo from '../images/membership/memTwo.png';

const converter = new Showdown.Converter();

const Membership = () => {
  
  const [event, setEvent] = useState(null);

  useEffect(() => {
    //unsure what schema is for this
    firebase.database().ref("membership").once("value", (snapshot) => {
      let eventObject = snapshot.val();
      if (eventObject) {
        let key = Object.keys(eventObject)[0];
        setEvent({
          id: key,
          ...eventObject[key]  
        });
      }
    });
  }, []);

  return (
    <div>
      <div className="row">
        <div id="membership">
          <h1 className="memberTitle"><b>Membership Program</b></h1>
          <br />
          <p>
            The HuskyTech Membership experience is designed to help students develop their tech careers
            holistically. We believe that a strong network, proficieint soft and technical skills, and a
            like-minded community will best allow students to facilitate their career in tech.
          </p>
        </div>
        <div id="image-div">
          <img className="imageWrapper" src={imageOne} alt="imageOne" />
        </div>
      </div>
      <div id="memberSkew" className="row">
        <div className="col-sm">
          <h3 className="memberHeading"><b>Workshops</b></h3>
          <br />
          <sub>
            Through workshops, we aim to provide active hands-on mentorship in developing soft skills and
            technical skills to help students succeed in the industry. Our soft skill workshops include
            topics on how to grow your network, create a polished resume, or best prepare for career fairs. 
            Our Technical Skills workshops cover topics such as acing technical interviews, building 
            strong profiles on LinkedIn, and learning about tech firms and their recruiting practices. 
            Students will learn, execute, and take away visible deliverables from each workshop. 
          </sub>  
        </div>
        <div className="col-sm">
          <h3 className="memberHeading"><b>Perks</b></h3>
          <br />
          <sub>
            Members will gain priority access to HuskyTech events to build connections with industry firms 
            and programs such as the annual HT Mentorship Program. HuskyTech members will also be featured on 
            our extensive sponsor database where recruiters can access member profiles and reach out regarding 
            industry opportunities. HT Member will have the opportunity to build a Strong Network within the 
            Seattle tech community and learning more about the industry as a whole.
          </sub>  
        </div>
        <div className="col-sm">
          <h3 className="memberHeading"><b>Activities</b></h3>
          <br />
          <sub>
            Throughout the quarter, we organize career-building activities for all members. These activities 
            will serve as an opportunity for members to grow their network at UW and engage with like-minded 
            people in tech. We aim to foster a strong sense of Community so members feel comfortable reaching out
            to the HuskyTech team or other members for advice. Activities are centered around vital career skills 
            with common challenges and their solutions, facilitating active thinking, and profile development.
          </sub>  
        </div>
      </div>
      <div className="row">
        <div id="membership">
          <h2 className="memberTitle"><b>Join our community.</b></h2>
          <br />
          <h5><b>Application Requirements</b></h5>
          <p>
            <br />
            1. Undergraduate student at the University of Washington, Seattle Campus. 
            <br />
            <br />
            2. Intending to pursue a career in the tech industry in Software Engineering, Product Management, Design, 
               or Data Science.
          </p>
          <sub>
            HT Membership Applications are currently closed.
          </sub>
        </div>
        <div>
          <img className="appImage" src={imageTwo} alt="logo" />
        </div>
      </div>
      <br />
      <br />

      <div id="eventVisuals">
        <h2 className="memberTitle>"><b>Past Membership Events</b></h2>
        <br />
        <div>
          {event ?
            <div className="row">
              <div className="col-12 col-md-8">
                <h3><b>{event.title}</b></h3>
                <h4><b><em>{event.quarter}</em></b></h4>
                <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(event.body) }}></div>
              </div>
              <div className="col-12 col-md-4">
                <img className="event-image" src={event.img} alt={event.img} />
              </div>
            </div>
            :
            <div className="spinner-area">
              <Spinner style={{ color: "#3db8f8" }} />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Membership;