import alex from '../images/team/alex.jpg';
import james from '../images/team/james.jpg';
import meghana from '../images/team/meghana.jpg';
import teya from '../images/team/teya.jpg';
import julia from '../images/team/julia.jpg';
import liesl from '../images/team/liesl.jpg';
import tommyhil from '../images/team/tommyhil.jpg';
import sruthi from '../images/team/sruthi.JPG';
import nachiket from '../images/team/nachiket.jpeg';
import albert from '../images/team/albert.jpeg';
import shawn from '../images/team/shawn.png';
import suchi from '../images/team/suchi.jpg';
import nathan from '../images/team/nathan.jpg';
import yuyu from '../images/team/yuyu.png';
import youjean from '../images/team/youjean.jpg';
import jacinta from '../images/team/jacinta.jpg';
import greyson from '../images/team/greyson.jpg';
import felicia from '../images/team/felicia.jpg';
import abheek from '../images/team/abheek.JPG';

import aatish from '../images/team/aatish.jpg';
import aheli from '../images/team/ahelii.png';
import azita from '../images/team/azita.jpg';
import deepesh from '../images/team/deepesh.png';
import yasmine from '../images/team/yasmine.jpg';
import kira from '../images/team/kira.png';
import ann from '../images/team/ann.jpg';

const teamMembers = {
  // teamData: [
  //   {
  //     name: "Teya McGuire",
  //     team: "HuskyTech",
  //     role: "President",
  //     picture: teya,
  //     linkedin: "https://www.linkedin.com/in/teya-mcguire/",
  //     email: "teyamc@uw.edu",
  //     description: "Teya is a junior studying Information Systems and Marketing. She is passionate about helping students find their paths in industry. ",
  //   },
  //   {
  //     name: "James Chen",
  //     team: "Finance",
  //     role: "Director of Finance",
  //     picture: james,
  //     linkedin: "https://www.linkedin.com/in/jamesmchen/",
  //     email: "Jmchen3@uw.edu",
  //     description: "James is a Junior who absolutely LOVES everything sports. He is currently majoring in Computer Engineering and interested in pursuing management roles in the future! His favorite quote ever is: \"The finance team is goals\" - HuskyTech Founder.",
  //     facebook: "https://www.facebook.com/jaaameschen/"
  //   },
  //   {
  //     name: "Julia Kim",
  //     team: "Finance",
  //     role: "Finance Associate",
  //     picture: julia,
  //     linkedin: "https://www.linkedin.com/in/julia-kim-7a4925149/",
  //     email: "jkim0036@gmail.com",
  //     description: "Julia is a junior studying Computer Science. She is excited to learn more about the tech industry and how she fits in to the different areas of study within computer science. She enjoys traveling, thrifting, and baking in her free time.",
  //     facebook: "https://www.facebook.com/julia.kim.9003888"
  //   },
  //   {
  //     name: "Alex Kim",
  //     team: "Tech",
  //     role: "Director of Tech",
  //     picture: alex,
  //     linkedin: "https://www.linkedin.com/in/alexjongkukkim",
  //     email: "kim.alex611@gmail.com",
  //     description: "Alex is currently a senior studying computer science at the University of Washington. He is specifically interested in Human Computer Interaction and its development towards greater technological inclusivity.",
  //     facebook: "https://www.facebook.com/alexander.kim.79219"
  //   },
  //   {
  //     name: "Nachiket Karmarkar",
  //     team: "Tech",
  //     role: "Student Developer",
  //     picture: nachiket,
  //     linkedin: "https://www.linkedin.com/in/nachiket-karmarkar-910197169/",
  //     email: "nkarm@uw.edu",
  //     description: "Nachiket is a freshman at UW majoring in Computer Science and intending to pursue an Entrepreneurship minor. In his free time, Nachiket enjoys playing tennis and spending time outdoors.",
  //     facebook: "https://www.facebook.com/nachiket.karmarkar.35",
  //   },
  //   {
  //     name: "Meghana Vinnakota",
  //     team: "Membership Team",
  //     role: "VP of Membership",
  //     picture: meghana,
  //     linkedin: "https://linkedin.com/in/meghana-vinnakota",
  //     email: "mvinna@uw.edu",
  //     description: "Meghana is a design and tech enthuiast studying Informatics/Geography at UW. She's currently a senior and will be joining Microsoft as a Product Manager upon graduation. In her free time, she loves to be creative and is trying to find new hobbies at the moment.",
  //     facebook: "https://www.facebook.com/meghana.vinny"
  //   },
  //   {
  //     name: "Tommyhil Doan",
  //     team: "Tech",
  //     role: "Student Developer",
  //     picture: tommyhil,
  //     linkedin: "https://www.linkedin.com/in/tommyhildoan/",
  //     email: "tommyhildoan@gmail.com",
  //     description: "Tommyhil is a freshman intending to study Computer Science at UW. He lives by the motto, “do what makes you scared” and loves taking on new challenges. In his free time he likes to spend time with his friends, watch movies, expand his photography skills, and explore new places.",
  //     facebook: "https://www.facebook.com/tommyhildoan"
  //   },
  //   {
  //     name: "Sruthi Dikkala",
  //     team: "HuskyTech",
  //     role: "Vice President",
  //     picture: sruthi,
  //     linkedin: "https://www.linkedin.com/in/sruthi-dikkala",
  //     email: "dikkala@uw.edu",
  //     description: "Sruthi is a senior in Informatics exploring how data and design can help tackle complex business problems. In her free time, she enjoys dancing, drawing and cooking.",
  //     facebook: "https://www.facebook.com/profile.php?id=100006606108254"
  //   },
  //   {
  //     name: "Albert Lam",
  //     team: "Events Team",
  //     role: "Operating Officer",
  //     picture: albert,
  //     linkedin: "https://www.linkedin.com/in/albert-klam/",
  //     email: "albertlam349@gmail.com",
  //     description: "Albert is a sophomore in Interdisciplinary Honors majoring in Informatics and Business. In his free time, he enjoys playing badminton and reading.",
  //   },
  //   {
  //     name: "Shawn Lee",
  //     team: "Mentorship Team",
  //     role: "Design Associate",
  //     picture: shawn,
  //     linkedin: "https://www.linkedin.com/in/shawnlee2k/",
  //     facebook: "https://www.facebook.com/profile.php?id=100005636946081",
  //     email: "sylee2k@uw.edu",
  //     description: "Shawn is a junior studying Computer Science and planning on going into software development. During his free time, he works on graphic design projects, does freelance portrait photography, and produces music."
  //   },
  //   {
  //     name: "Suchi Sridhar",
  //     team: "Events Team",
  //     role: "Product Manager",
  //     picture: suchi,
  //     linkedin: "https://www.linkedin.com/in/suchisridhar/",
  //     facebook: "https://www.facebook.com/profile.php?id=100004878075802",
  //     email: "suchi@cs.uw.edu",
  //     description: "Suchi is a junior double majoring in Computer Science and Philosophy. She is passionate about data science, the intersection of computer science and design, and creating technology for social good. She loves HGTV, college basketball, and small dogs."
  //   },
  //   {
  //     name: "Nathan Chen",
  //     team: "Corporate Relations",
  //     role: "Corporate Relations Associate",
  //     picture: nathan,
  //     linkedin: "https://www.linkedin.com/in/nchen5/",
  //     email: "nchen5@cs.uw.edu",
  //     description: "Nathan is UW student majoring in Computer Science and minoring in Education. In his free time, he enjoys reading, making desserts, and tending to his fake plants."
  //   },
  //   {
  //     name: "YuYu Madigan",
  //     team: "Membership Team",
  //     role: "Operating Officer",
  //     picture: yuyu,
  //     linkedin: "https://www.linkedin.com/in/fymadigan",
  //     facebook: "https://www.facebook.com/profile.php?id=100009541052504",
  //     email: "fywm@uw.edu",
  //     description: "YuYu is a junior studying Economics and intererested in tech consulting, entreprenuership, and sustainability. During her free time, she enjoys playing piano and anything food related!"
  //   },
  //   {
  //     name: "Jacinta Harshe",
  //     team: "Membership Team",
  //     role: "Marketing Associate",
  //     picture: jacinta,
  //     email: "jharshe@uw.edu",
  //     linkedin: "https://www.linkedin.com/in/jacinta-harshe/",
  //     description: "Jacinta is a junior at UW studying communications with a minor in informatics. She is intrigued by the intersection between business, tech, and human interaction. In her free time, you can find her going to concerts or playing with photography.",
  //   },
  //   {
  //     name: "Greyson Fields",
  //     team: "Mentorship Team",
  //     role: "Product Manager",
  //     picture: greyson,
  //     email: "gfields5@uw.edu",
  //     linkedin: "https://www.linkedin.com/in/fieldsg/",
  //     description: "Greyson is a senior studying Informatics and will be joining VMware full-time as a Product Manager. In his free time, you can find him exploring the neighborhoods of Seattle or on adventures in the Cascades.",
  //   },
  //   {
  //     name: "Felicia Yan",
  //     team: "Corporate Relations",
  //     role: "Corporate Relations Associate",
  //     picture: felicia,
  //     email: "yfelicia@uw.edu",
  //     linkedin: "https://www.linkedin.com/in/felicia-y/",
  //     description: "Felicia is a junior studying Computer Science and Business Administration, and is planning to pursue a career in consulting. This summer, she will be interning with McKinsey & Company. Outside of class, she enjoys working with kids and is a rhythmic gymnastics coach. ",
  //     facebook: "https://www.facebook.com/felicia.yan.7",
  //   },
  //   {
  //     name: "Liesl Block",
  //     team: "Events Team",
  //     role: "Marketing Associate",
  //     picture: liesl,
  //     email: "liesl.block@gmail.com",
  //     linkedin: "https://www.linkedin.com/in/lieslblock/",
  //     description: "Liesl is a senior studying Psychology and Informatics, intending to pursue user research. She is passionate about intersecting social justice with the tech industry. In her free time, you can catch her thrifting, hiking, and spending time with her friends.",
  //     facebook: "https://www.facebook.com/liesl.block"
  //   },
  //   {
  //     name: "Youjean Cho",
  //     team: "Events Team",
  //     role: "Design Associate",
  //     picture: youjean,
  //     email: "youjean@uw.edu",
  //     linkedin: "linkedin.com/in/youjean-cho-29a3581ab",
  //     description: "Youjean is a sophomore studying Interaction Design. She is passionate about design, technology, and visual storytelling. She spends her free time working on personal design projects, 3D animating, and exercising at the gym. ",
  //     facebook: "https://www.facebook.com/youjean.cho"
  //   },
  //   {
  //     name: "Abheek Chakrabarti",
  //     team: "Events Team",
  //     role: "Product Manager",
  //     picture: abheek,
  //     email: "abheekc8@gmail.com",
  //     description: "Abheek is a sophomore majoring in Computer Science. He is also interested in pursuing a minor in Computational Finance. Previously, Abheek served as an Operations Officer  within HuskyTech's Mentorship division. Abheek is passionate about encouraging intellectual diversity and inclusivity in his work.",
  //   }
  // ]
  teamData: [
    {
      name: "Aheli Dutta",
      team: "HuskyTech",
      role: "President",
      picture: aheli,
      linkedin: "https://www.linkedin.com/in/aheli-dutta-1402201b3/",
      email: "ahelid78@uw.edu",
      description: "Aheli is a sophomore at UW studying Computer Science. She likes playing soccer, reading books, and eating sushi in her free time. ",
    },
    {
      name: "Aatish Parson",
      team: "HuskyTech",
      role: "Vice President",
      picture: aatish,
      linkedin: "https://www.linkedin.com/in/aatish-parson-7481a9214/",
      email: "aatishp@uw.edu",
      description: "Aatish is a sophomore at UW studying Computer Science. He is interested in building mobile apps, playing sports, and exploring the Ave. ",
    },
    {
      name: "Deepesh Singh",
      team: "HuskyTech",
      role: "Executive Director",
      picture: deepesh,
      linkedin: "https://www.linkedin.com/in/deepesh-singh-wa/",
      email: "dsingh01@uw.edu",
      description: "Deepesh is a sophomore at UW studying Computer Science and Business. He is interested in the intersection of both fields, especially the quant space. In his free time, Deepesh enjoys going to the gym and playing sports. ",
    },
    {
      name: "Azita Balsara",
      team: "HuskyTech",
      role: "Executive Director",
      picture: azita,
      linkedin: "https://www.linkedin.com/in/azita-balsara-914580248/",
      email: "azitab@uw.edu",
      description: "Azita is a junior at UW studying Computer Science. Outside of school, she enjoys lifting, reading and painting. ",
    },
    {
      name: "Yasmine Shubber",
      team: "HuskyTech",
      role: "Director of Operations",
      picture: yasmine,
      linkedin: "https://www.linkedin.com/in/yshubber",
      email: "yshubber@uw.edu",
      description: "Yasmine is a second year Computer Science student. In her free time she likes hiking, cooking, and shopping. ",
    },
    {
      name: "Kira Brodsky",
      team: "HuskyTech",
      role: "Director of Marketing",
      picture: kira,
      linkedin: "https://www.linkedin.com/in/kira-brodsky-90a11a275",
      email: "kbrods@uw.edu",
      description: "Kira is a sophomore at UW, intending to study HCDE. She is interested in UX and UI design, as well as anything related to the arts. ",
    },
    {
      name: "Ann Baturytski",
      team: "HuskyTech",
      role: "Director of Member Services",
      picture: ann,
      linkedin: "https://www.linkedin.com/in/annbat/",
      email: "abatur@uw.edu",
      description: "Ann is a current senior studying CS. She’s interested in AI, data science and back end. She also enjoys skiing, hiking and playing video games. ",
    }

  ]
}

export { teamMembers };

/*
*/