import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import firebase from 'firebase/app';
import * as dates from '../utils/dates';
import moment from 'moment';
import './Calendar.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { isObject } from 'reactstrap/lib/utils';

const localizer = momentLocalizer(moment);

let CalendarComp = () => {
    const [events, changeEvents] = useState([]);

    useEffect(() => {
        firebase.database().ref("events").once("value", (snapshot) => {
            let snap = snapshot.val();
            let confirmedEvents = [];
            if (snap) {
                Object.keys(snap).forEach(key => {
                    let e = snap[key];
                    if (e.date && e.name && e.type && e.start && e.end) {
                        confirmedEvents.push(e);
                    }
                });
            }

            // Format events for big calendar
            let formattedEvents = [];
            for (let i = 0; i < confirmedEvents.length; i++) {
                let fbEvent = confirmedEvents[i];
                let trueStart = convertToDate(fbEvent.date + " " + fbEvent.start);
                let trueEnd = convertToDate(fbEvent.date + " " + fbEvent.end);
                const bcEvent = {
                    id: i,
                    title: fbEvent.name,
                    type: fbEvent.type,
                    start: trueStart,
                    end: trueEnd
                }
                formattedEvents.push(bcEvent);
            }
            changeEvents(formattedEvents);
        });
    }, []);

    const convertToDate = (time) => {
        return new Date(time);
    }

    return (
        <div id="calendar-wrapper">
            <h1 className="htHeading htHeaderFont" id="sponsorHeader">Calendar</h1>
            <div id="calendar-content-wrapper">
                <div className="topLegend">
                    <div className="inlineShift">
                        <p className="htHeaderFont whoCanAttend">Who can attend?</p>
                    </div>
                    <div className="inlineShift spaceRightShort">
                        <div className="colorBox" style={{backgroundColor: "#6E9FFF"}}></div>
                        <div className="inlineShift spaceRight">
                            <p className="htBodyFont">Open to Everyone!</p>
                        </div>
                    </div>

                    <div className="inlineShift spaceRightShort">
                        <div className="colorBox" style={{backgroundColor: "#AE9FFF"}}></div>
                        <div className="inlineShift spaceRight">
                            <p className="htBodyFont">HT Members</p>
                        </div>
                    </div>

                    <div className="inlineShift spaceRightShort">
                        <div className="colorBox" style={{backgroundColor: "#63D1FF"}}></div>
                        <div className="inlineShift spaceRight">
                            <p className="htBodyFont">HT Mentorship Program</p>
                        </div>
                    </div>
                </div>
                <Calendar
                    events={events}
                    style={{ height: "80vh", fontFamily: "Karla", fontSize: "16px", paddingTop: "10px" }}
                    step={60}
                    showMultiDayTimes
                    max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                    localizer={localizer}
                    eventPropGetter={
                        (event, start, end, isSelected) => {
                            // Customize color of event based on event type
                            let newStyle= {
                                backgroundColor: '#6E9FFF' 
                            }
                            if (event.type === 'membership') {
                                newStyle.backgroundColor = '#AE9FFF'
                            } else if (event.type === 'mentorship') {
                                newStyle.backgroundColor = '#63D1FF'
                            }
                            return {
                                style: newStyle
                            }
                        }
                    }
                />
            </div>
        </div>
    );
}

export default CalendarComp;