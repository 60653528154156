import React, { useState } from 'react';
import { Row, Col, Button, Container, Card, CardImg, Modal } from 'reactstrap';
import { logEvent }  from '../helpers';
import './Team.css';

import email_logo from '../images/team-page-assets/icons8-new-post-80.png';
import email_filled from '../images/team-page-assets/email-filled.png';
import linkedin_logo from '../images/team-page-assets/icons8-linkedin-128.png';
import linkedin_filled from '../images/team-page-assets/linkedin-filled.png';
import facebook_logo from '../images/team-page-assets/icons8-facebook-64.png';
import facebook_filled from '../images/team-page-assets/fb-filled.png';
import close_icon from '../images/team-page-assets/x-for-alex.png';

const TeamMember = (props) => {
  const [modal, setModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);

  const toggle = () => {
    setModal(!modal);
  }

  const handleClose = (e) => {
    e.preventDefault();
    setModal(false)
  }

  const renderProfileModal = () => {
    if (modalDetails) {
      let { name, team, picture, linkedin, email, description, facebook, portfolio } = modalDetails;

      return (
        <div>
          <Row style={{ margin: 0 }}>
            <Col className="modalPicture" md="6" style={{ padding: 0 }}>
              <img src={picture} alt="member" className="img-fluid" />
            </Col>
            <Col md="6" style={{ padding: "30px" }}>
              <h1 className="modalName"> {name}</h1>
              <h5 className="modalTeam"> {team}</h5>
              <p className="modalText" > {description} </p>
              <div className="buttons">
                <div className="socialLinks" >
                  <div></div>
                  {email && <a href={"mailto:" + email}>
                    <img className="social-icon" onMouseOver={(e) => e.currentTarget.src = email_filled} onMouseOut={(e) => e.currentTarget.src = email_logo} src={email_logo} width={30} alt="click to email me" onClick={() => logEvent("clicked_" + props.name.toLowerCase().replace(/\s/g, "_") + "_email")}/>
                  </a>}
                  {linkedin && <a href={"" + linkedin}>
                    <img className="social-icon" onMouseOver={(e) => e.currentTarget.src = linkedin_filled} onMouseOut={(e) => e.currentTarget.src = linkedin_logo} src={linkedin_logo} width={30} alt="click to see my linkedin page" onClick={() => logEvent("clicked_" + props.name.toLowerCase().replace(/\s/g, "_") + "_linkedin")}/>
                  </a>}
                  {facebook && <a href={"" + facebook} >
                    <img className="social-icon" onMouseOver={(e) => e.currentTarget.src = facebook_filled} onMouseOut={(e) => e.currentTarget.src = facebook_logo} src={facebook_logo} width={30} alt="click to see my facebook page" onClick={() => logEvent("clicked_" + props.name.toLowerCase().replace(/\s/g, "_") + "_facebook")}/>
                  </a>}
                  <div></div>
                </div>
                <div style={{ textAlign: "center" }}>
                  {portfolio ? (
                    <Button href={"" + portfolio} className="modalPortfolio" onClick={() => logEvent("clicked_" + props.name.toLowerCase().replace(/\s/g, "_") + "_portfolio_website")}>Portfolio Website</Button>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <img src={close_icon} alt="Closing icon" className="closingIcon" onClick={handleClose} />
        </div>
      );
    } else {
      return null;
    }
  }

  const handleImageClick = (e) => {
    e.preventDefault();
    setModal(true);
    setModalDetails(props.data);
    logEvent("clicked_" + props.name.toLowerCase().replace(/\s/g, "_"));
  }

  return (
    <div>
      <div className="team-modal">
        <Modal className="team-modal" centered={true} isOpen={modal} size="lg" fade={true} toggle={toggle}>
          {renderProfileModal()}
        </Modal>
      </div>
      <div>
        <Card className="adjustCard">
          <CardImg style={{ cursor: "pointer" }} top src={props.picture} onClick={handleImageClick}></CardImg>
        </Card>
        <div className="nameMargin">
          <span className="colorWords">
            {props.name}
          </span>
          <br />
          <span>
            {props.team}
          </span>
        </div>
      </div>
    </div>
  );
}
export default TeamMember;
