import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Spinner } from 'reactstrap';
import * as Showdown from "showdown";
import './Mentorship.css';
import imageOne from '../images/mentorship/mentorOne.jpg';
import imageTwo from '../images/mentorship/mentorTwo.jpg';

const converter = new Showdown.Converter();

const Mentorship = () => {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    //unsure what schema is for this data
    firebase.database().ref("mentorship").once("value", (snapshot) => {
      let eventObject = snapshot.val();
      if (eventObject) {
        let key = Object.keys(eventObject)[0];
        setEvent({
          id: key,
          ...eventObject[key]  
        });
      }
    });
  }, []);

  return (
    <div>
      <div className="row">
        <div id="mentorship">
          <h1 className="mentorTitle"><b>Mentorship Program</b></h1>
          <br />
          <p>
          Discover how you can navigate the path to your career destination. Mentees will explore 
          career options, connect with industry professionals, and meet like-minded peers through 
          hosted events and a mentor we match to your personal interests and skills. <br /><br /> 
          Our Mentorship program is specifically geared toward motivated, career-driven students 
          pursuing software engineering, data science, product management, or design.
          </p>
        </div>
        <div id="image-div">
          <img className="imageWrapper" src={imageOne} alt="logo" />
        </div>
      </div>
      <div id="mentorSkew" className="row">
        <div className="col-sm">
          <h3 className="mentorHeading"><b>A quarter long exploration.</b></h3>
          <br />
          <sub>
          Each student will be paired with a mentor based on matching interests, backgrounds and 
          career fields. Learn about different opportunities available in tech in order to think 
          beyond your college education. <br /><br />In addition to the one-on-one sessions 
          mentees will have with their mentors, HuskyTech will plan a variety of events including: 
          <b> networking sessions, mock interviews, AMA panels with the sponsor network, job 
          shadowing, company tours, and more!</b><br /><br /> HuskyTech's Mentorship Program provides 
          the connections and experiences you will need to discover your personal path in tech.
          </sub>  
        </div>
        <div className="col-sm">
          <div id="image-div">
            <img className="skewImage" src={imageTwo} alt="logo" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3 className="mentorTitle"><b>Mentees</b></h3>
          <p>
          Mentorship Applications are currently closed. Keep an eye out for the next cycle’s application!
          </p>
          <br />
          <h5 className="mentorTitle"><b>Application Requirements</b></h5>
          <p>
            Undergraduate student at the University of Washington, Seattle Campus. 
            <br />
            <br />
            Intending to pursue a career in the tech industry in Software Engineering, Product Management, Design, 
            or Data Science.
          </p>
          <sub>
            Mentorship Applications are currently closed. Keep an eye out on our Facebook 
            for next quarter’s application!
          </sub>
        </div>
        <div className="col">
          {/* <h3 className="mentorTitle"><b>Mentors</b></h3>
          <p>
            We are looking for passionate mentors interested in being a part of the largest tech 
            mentorship program at the University of Washington. Join today to take part in this amazing opportunity!
          </p>
          <br /> */}
          {/* <h5 className="mentorTitle"><b>Application Requirements</b></h5>
          <p>
            Undergraduate student at the University of Washington, Seattle Campus. 
            <br />
            <br />
            Intending to pursue a career in the tech industry in Software Engineering, Product Management, Design, 
            or Data Science.
          </p>
          <sub>
            Mentorship Applications are currently closed. Keep an eye out on our Facebook 
            for next quarter’s application!
          </sub> */}
        </div>
      </div>
      <br />
      <br />
      <br />
      <h2><b>Past Mentorship Events</b></h2>
      <br />
      <div>
        {event ?
          <div className="row">
            <div className="col-12 col-md-8">
              <h3><b>{event.title}</b></h3>
              <h4><b><em>{event.year}</em></b></h4>
              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(event.body) }}></div>
            </div>
            <div className="col-12 col-md-4">
              <img className="event-image" src={event.img} alt={event.img} />
            </div>
          </div>
          :
          <div className="spinner-area">
            <Spinner style={{ color: "#3db8f8" }} />
          </div>
        }
      </div>      
   </div>
  );
}

export default Mentorship;
