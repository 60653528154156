import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import firebase from 'firebase';
import { Spinner } from 'reactstrap';
import * as Showdown from "showdown";
import './blogPost.css';

import closeIcon from '../images/team-page-assets/x-for-alex.png';

// creates a default convertor
const converter = new Showdown.Converter();


const months = ["January", "February", "March", "April", "May", "June", "July", "August",
    "September", "October", "November", "December"]
    
const getDateString = (date) => {
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + day + ", " + year;
}

const BlogPost = (props) => {
    const [post, setPost] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        let postId = props.match.params.postId;
        firebase.database().ref("blog").child(postId).once("value", (snapshot) => {
            if (!snapshot.exists) {
                setRedirect(true);
            }
            setPost(snapshot.val());
        });
    }, []);

    return (
        <div>
            <button className="mt-2 back-btn" onClick={() => setRedirect(true)}><b>Back</b></button>
            <div className="container col-12 col-md-7" id="post">
                {redirect && <Redirect to="/blog" />}
                <div>
                    {post ?
                        <div>
                            <div>
                                <h1 className="title">
                                    {post.title}
                                </h1>
                                <p className="subtext">
                                    {getDateString(new Date(post.date))}
                                </p>
                                <p>
                                    {post.subtitle}
                                </p>
                                <br />
                                <div className="text-center">
                                    <img className="postimage" src={post.img} alt={post.img} />
                                </div>
                            </div>
                            <br />
                            <br />
                            {/* Takes post body from Firebase, converts it from Markdown to HTML */}
                            <div className="body" dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.body) }}></div>
                            <br />
                            {post.links && post.links.length > 0 && <h3>Attachments</h3>}
                            <div className="preview-links">
                                {post.links && post.links.map((link) =>
                                    <a key={link.fileName} className="preview-link" href={link.url} target="_blank" rel="noopener noreferrer">
                                        <div className="col-3">
                                            <img className="file-logo" src={closeIcon} alt="logo" />
                                        </div>
                                        <div className="col-9">{link.fileName}</div>
                                    </a>
                                )}
                            </div>
                        </div>
                        :
                        <div className="spinner-area">
                            <Spinner style={{ color: "#3db8f8" }} />
                        </div>
                    }
                </div>
            </div>
            <br />
        </div>
    )
}

export default BlogPost;