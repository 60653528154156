import React, { useState } from 'react';
import './NavBar.css';
import '../App.css';
import { logEvent } from '../helpers';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import MainLogo from '../images/logos/HTLogo.png';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Responsible for "Programs" dropdown jsx content
  const dropdownItems = ["events", "membership", "mentorship"];
  const dropdownOptions = dropdownItems.map(option => {
    const formattedOption = formatString(option);
    return (
      <DropdownItem key={option}>
        <NavItem>
          <NavLink to={`/${option}`} activeClassName="selectedDropdown" tag={RRNavLink} onClick={() => logEvent(`/${option}_clicked`)}>
            {formattedOption}
          </NavLink>
        </NavItem>
      </DropdownItem>
    );
  });

  // Responsible for remainder of navbar options aside from "Programs"
  const navOptions = ["calendar", "sponsors", "blog", "aboutus", "contact"];
  const navButtons = navOptions.map(option => {
    const visualName = formatString(option);
    return (
      <NavItem key={option} className="navOption">
        <NavLink to={`/${option}`} activeClassName="selected" tag={RRNavLink} onClick={() => logEvent(`${option}_clicked`)}>
          {visualName}
        </NavLink>
      </NavItem>
    );   
  });

  // helper functions
  function formatString(s) {
    if (s === 'aboutus') {
      return 'About Us';
    } else if (s === 'blog') {
      return 'HT Blog'
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  return (
    <Navbar light expand="md" id="mainNav">
        <NavbarBrand href="/">
          <img className="navLogo" src={MainLogo} alt="mainLogo"/>
        </NavbarBrand>

        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto htBodyFont htBody" id="huskyNav" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                Programs
              </DropdownToggle>
              <DropdownMenu>
                {dropdownOptions}
              </DropdownMenu>
            </UncontrolledDropdown>
            {navButtons}
          </Nav>
        </Collapse>
    </Navbar>
  );
}

export default NavBar;