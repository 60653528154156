import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Spinner } from 'reactstrap';
import * as Showdown from "showdown";
import './event.css';
import EventCard from './EventCard';

const converter = new Showdown.Converter();

const Events = () => {
  const [pastEvents, setPastEvents] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState(null)

  useEffect(() => {
    firebase.database().ref("events").once("value", (snapshot) => {
      let eventObject = snapshot.val();
      if (eventObject) {
        let pastevents = []
        let upcomingevents = []
        let eventKeys = Object.keys(eventObject)
        for(let i = 0 ; i < eventKeys.length; i++){
          let key = eventKeys[i];
          if(eventObject[key]['date'] == undefined){
            pastevents.push({
              id: key,
              ...eventObject[key] 
            })
          }else{
            upcomingevents.push({
              id: key,
              ...eventObject[key] 
            })
          }
          
        }
        setPastEvents(pastevents)
        setUpcomingEvents(upcomingevents)
      }
    });
  }, []);

  return (
    <div>
      <h1 className="title"><b>Events Program</b></h1>
      <br />
      <p>Each quarter, HuskyTech holds a large-scale conference with three or more of our sponsors where students can participate
        in interactive activities that focus on networking with industry professionals and peers and fostering a strong community.
        In the past, our conferences have included redesign sprints, hackathons, interactive information sessions, tech talks, and
        company projects. Each event is designed to give students the skills and insights to be competitive and competent candidates
        for industry. By the end of each session, members walk away with specific technical skills to use in interviews, and a
        diverse network of professionals and peers to build upon.</p>
      <br />
      <h2><b>UPCOMING EVENTS</b></h2>
      <br />
      <div>
        {upcomingEvents ?
          upcomingEvents.map((event) => 
          <div className="row">
            <div className="col-12 col-md-8">
              <h3><b>{event.title}</b></h3>
              <h4><b><em>{event.date}</em></b></h4>
              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(event.body) }}></div>
            </div>
            <div className="col-12 col-md-4">
              <img className="event-image" src={event.img} alt={event.img} />
            </div>
          </div>
          
          )
          :
          <div className="spinner-area">
            <Spinner style={{ color: "#3db8f8" }} />
          </div>
        }
      </div>
      <h2><b>PAST EVENTS</b></h2>
      <br />
      <div>
        {pastEvents ?
          pastEvents.map((event) => 
          <div className="row">
            <div className="col-12 col-md-8">
              <h3><b>{event.title}</b></h3>
              <h4><b><em>{event.quarter}</em></b></h4>
              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(event.body) }}></div>
            </div>
            <div className="col-12 col-md-4">
              <img className="event-image" src={event.img} alt={event.img} />
            </div>
          </div>
          
          )
          :
          <div className="spinner-area">
            <Spinner style={{ color: "#3db8f8" }} />
          </div>
        }
      </div>
    </div>
  );
}

export default Events;
