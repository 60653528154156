import React from 'react';
import { Helmet } from "react-helmet";
import { HashRouter, Route } from 'react-router-dom';
import './HomePage/HomePage.jsx';
import Team from './AboutUs/Team.jsx';
import ContactPage from './ContactPage/ContactPage.jsx';
import NavBar from './Navbar/NavBar.jsx';
import HomePage from './HomePage/HomePage.jsx';
import Events from './EventsPage/Events.jsx';
import Membership from './Programs/Membership.jsx';
import Mentorship from './Programs/Mentorship.jsx';
import CalendarComp from './CalendarPage/CalendarComp.jsx';
import SponsorshipPage from './SponsorshipPage/SponsorshipPage.jsx';
import BlogPage from './BlogPage/Blog';
import BlogPostPage from './BlogPostPage/BlogPost';
import './App.css';

const App = () => {
    return (
		<HashRouter>
			<div className="sitePadding">
				<Helmet>
					<meta charSet="utf-8" />
					<title>HuskyTech</title>
				</Helmet>
				<NavBar />
				<Route path="/" exact component={HomePage} />
				<Route path="/events" component={Events} />
				<Route path="/membership" component={Membership} />
				<Route path="/mentorship" component={Mentorship} />
				<Route path="/calendar" component={CalendarComp} />
				<Route path="/sponsors" component={SponsorshipPage} />
				<Route path="/aboutus" component={Team} />
				<Route path="/blog" component={BlogPage} exact />
				<Route path="/blog/:postId" component={BlogPostPage} />
				<Route path="/contact" component={ContactPage} />
			</div>
		</HashRouter>
    );
}

export default App;
