import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import './blog.css';
import { Link } from 'react-router-dom';
import { Spinner, Container, Row } from 'reactstrap';

const months = ["January", "February", "March", "April", "May", "June", "July", "August",
    "September", "October", "November", "December"]
    
const getDateString = (date) => {
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + day + ", " + year;
}

const Blog = () => {
    const [isLoaded, setLoaded] = useState(false);
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        firebase.database().ref("blog").once("value", (snapshot) => {
            let blogObject = snapshot.val();
            let blogArray = [];
            if (blogObject) {
                Object.keys(blogObject).forEach((post) => {
                    blogArray.push({
                        id: post,
                        ...blogObject[post]
                    });
                });
            }
            setPostData(blogArray);
            setLoaded(true);
        });
    }, []);

    const renderResults = () => {
        let renderedPosts = postData.sort((post1, post2) => post2.date - post1.date).map(post =>
            <Link to={`/blog/${post.id}`} className="no-underline" key={post.title}>
                <div className="blog-container row m-2">
                    <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                        <img className="postimage" src={post.img} alt={post.img} />
                    </div>
                    <div className="postinfo col-12 col-md-7">
                        <br />
                        <h3 className="posttitle">{post.title}</h3>
                        <p className="postsummary">{post.summary}</p>
                        <br />
                        <p className="postdate">{getDateString(new Date(post.date))}</p>
                    </div>
                </div>
            </Link>
        );

        if (renderedPosts.length === 0) {
            renderedPosts = (
                <div>
                    <Container>
                        <Row className="no-blogs">
                            <span className="upcomingEvents">No blogs available right now. But be sure to check back later for more awesome content!</span>
                        </Row>
                    </Container>
                </div>
            );
        }
        return renderedPosts;
    }

    const loadSpinner = () => {
        return (
            <div className="spinner-area">
                <Spinner style={{ color: "#3db8f8" }} />
            </div>
        );
    }

    return (
        <div>
            <div className="container">
                {isLoaded ? renderResults() : loadSpinner()}
            </div>
        </div>
    )
}

export default Blog;