import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import FilterOptions from './FilterOptions';
import TeamMember from './TeamMember';
import { teamMembers } from './Staff';
import './Team.css';

import TeamGroup from '../images/team/oldteam.jpg';

const Team = () => {
    const [filter, changeFilter] = useState('All');
    const [staff, changeStaff] = useState(teamMembers.teamData);

    let gridArray = staff.map(person => {
      if (person.team === filter || filter === "All") {
        return(
            <Col key={person.name} lg="3" xs="6" sm="3" className="columnContent">
              <TeamMember picture={person.picture} name={person.name} team={person.role} data={person}/>
            </Col>
        )
      } else {
        return null;
      }
    });

    return (
      <div>
        <h1 className="htHeading htHeaderFont" id="sponsorHeader">About Us</h1>

        <div className="htBodyFont htBody" id="overview">
          <p>
            HuskyTech is a non-profit organization that helps students at the University of Washington 
            accelerate {<br />} their tech careers by connecting them to industry professionals, hosting career
            development {<br />} workshops, and helping them build a strong network of like-minded individuals.
          </p>
          <br />
          <p>
          Originally founded in 2018, our 2023 team looks to revamp the club and bring it to new heights.
          </p>
        </div>
        {/* <div id="teamImage">
          <img className="topImage" src={TeamGroup} />
        </div> */}
        <Container id="bottomWrapper">
          <Row>
            <Col sm="3">
              {/* <FilterOptions 
                currFilter={filter}
                setFilter={changeFilter}
              /> */}
              <br/>
              <p className="htBodyFont">
                <i>Click on each team member to learn more about them and 
                connect with them on social media!</i>
              </p>
            </Col>
            <Col sm="9">
              <Row>
                {gridArray}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default Team;
