import React from 'react';
import '../App.css';
import '../index.css';
import './HomePage.css';
import Rocket from '../images/page-assets/rocket.png';
import EventLogo from '../images/logos/HTLogoEvents.png';
import MembershipLogo from '../images/logos/HTLogoMembership.png';
import MentorshipLogo from '../images/logos/HTLogoMentorship.png';

const isVerticalLayout = () => {
  return window.innerHeight > window.innerWidth;
}


const HomePage = () => {

    if(isVerticalLayout()){
      return(
        <div className="homeWrapper">
            <h1 id="mainHeading"><b>Accelerate your tech career</b></h1>
            <p className="htBody">
              HuskyTech is a University of Washington Registered Student 
              Organization that is dedicated to simplifying the journey for 
              students into the tech industry through membership,
              mentorship, and public events.
            </p>
        
            <div>
              <h2 className="htSubHeading htHeaderFont" id="programHeader"><b>Our programs</b></h2>
              <p className="htBody">
                HuskyTech Programs are designed for students looking to take their tech career journey to the next level.
                With sponsored, large-scale events, personalized member workshops, and the opportunity to connect with industry
                professionals, the HuskyTech program lineup provides key resources to incoming industry talent.
              </p>
            </div>
            <div><p><br/><br/></p></div>
        <div className="row">
       
          <div className="col-sm-1"></div>
          <div className="col-sm-6 logoSection">
            <div className="row">
              <img src={EventLogo} alt="eventLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/events" className="page-links">Events</a></b>
                  <p className="subtext">Public events that are open to all students who are interested.</p>
                </h4>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="row">
              <img src={MembershipLogo} alt="membershipLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/membership" className="page-links">Membership</a></b>
                  <p className="subtext">A select program that gives priority access to our events and resources.</p>
                </h4>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="row">
              <img src={MentorshipLogo} alt="mentorshipLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/mentorship" className="page-links">Mentorship</a></b>
                  <p className="subtext">Our spring program allows students to connect directly with industry professionals.</p>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }
    return (
      <div className="homeWrapper">
        <div className="row" id="homeTop">
          <div id="image-div">
            <img src={Rocket} alt="temporary" id="rocketImage"/>
          </div>
          <div id="accelerate-career">
            <h1 id="mainHeading"><b>Accelerate your tech career</b></h1>
            <p className="htBody">
              HuskyTech is a University of Washington Registered Student 
              Organization that is dedicated to simplifying the journey for 
              students into the tech industry through membership,
              mentorship, and public events.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <div>
              <h2 className="htSubHeading htHeaderFont" id="programHeader"><b>Our programs</b></h2>
              <p className="htBody">
                HuskyTech Programs are designed for students looking to take their tech career journey to the next level.
                With sponsored, large-scale events, personalized member workshops, and the opportunity to connect with industry
                professionals, the HuskyTech program lineup provides key resources to incoming industry talent.
              </p>
            </div>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-6 logoSection">
            <div className="row">
              <img src={EventLogo} alt="eventLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/events" className="page-links">Events</a></b>
                  <p className="subtext">Public events that are open to all students who are interested.</p>
                </h4>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="row">
              <img src={MembershipLogo} alt="membershipLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/membership" className="page-links">Membership</a></b>
                  <p className="subtext">A select program that gives priority access to our events and resources.</p>
                </h4>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="row">
              <img src={MentorshipLogo} alt="mentorshipLogo" className="smallLogo" />
              <div className="logoText">
                <h4>
                  <b><a href="./#/mentorship" className="page-links">Mentorship</a></b>
                  <p className="subtext">Our spring program allows students to connect directly with industry professionals.</p>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default HomePage;